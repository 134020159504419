<template>
  <div>
    <a-row style="margin-bottom: 16px">
      <a-col :span="16">
        <b>{{ pluralize("Alias", team.aliases.length) }}:</b>
        <span v-for="(aliasHtml, index) in highlightedAliases" :key="index">
          <span v-if="index > 0">,</span>
          <HighlightContent :contentHtml="aliasHtml" />
        </span>
        <span v-if="highlightedAliases.length && nonHighlightedAliases.length"
          >,</span
        >
        <span>{{ nonHighlightedAliases.join(", ") }} </span>
        <span v-if="notDisplayedAliasesCount > 0">
          and {{ notDisplayedAliasesCount }} more
        </span>
      </a-col>
    </a-row>
    <a-row style="margin-bottom: 16px">
      <b>Description:</b>
      <HighlightContent
        v-if="isHighlighted('responsibilities')"
        :contentHtml="highlights['responsibilities'].join('[...]')"
      />
      <span v-else>
        {{ truncatedData(team.responsibilities, 400) }}
      </span>
    </a-row>
    <a-row>
      <span v-for="property in highlightedCustomProperties" :key="property.key">
        <a-row style="margin-bottom: 16px">
          <b>{{ property.name }}:</b>
          <template v-if="isNestedValue(property.key)">
            <p>
              <b style="margin-left: 32px">
                {{ keyWithoutPrefix(property.key) }}:
              </b>
              <template v-for="highlight in property.highlights">
                <HighlightContent :key="highlight" :contentHtml="highlight" />
              </template>
            </p>
          </template>
          <template v-else>
            <template v-for="highlight in property.highlights">
              <HighlightContent :key="highlight" :contentHtml="highlight" />
            </template>
          </template>
        </a-row>
      </span>
      <span
        v-if="notDisplayedCustomPropertiesCount > 0"
        style="margin-left: 16px"
      >
        and {{ notDisplayedCustomPropertiesCount }} more
        {{ pluralize("property", notDisplayedCustomPropertiesCount) }}
      </span>
    </a-row>
  </div>
</template>

<script>
import pluralize from "pluralize";
import { truncate } from "lodash";
import HighlightContent from "@/components/atoms/HighlightContent.vue";

const ALIASES_LIMIT = 5;
const PROPERTIES_LIMIT = 3;

export default {
  components: {
    HighlightContent,
  },

  props: {
    team: {
      type: Object,
      required: true,
    },
  },

  computed: {
    highlights() {
      return this.team.highlights || {};
    },
    highlightedProperties() {
      return Object.keys(this.highlights);
    },
    highlightedCustomProperties() {
      if (this.isHighlighted("properties")) {
        return this.highlights["properties"].slice(0, PROPERTIES_LIMIT);
      }

      return [];
    },
    highlightedAliases() {
      let aliases = [];

      if (this.isHighlighted("friendly_id_slugs.slug")) {
        const slugsToHighlight = this.highlights[
          "friendly_id_slugs.slug"
        ].slice(0, ALIASES_LIMIT);

        aliases = aliases.concat(slugsToHighlight);
      }

      if (this.isHighlighted("aliases.value")) {
        const aliasesToHighlight = this.highlights["aliases.value"].slice(
          0,
          ALIASES_LIMIT - aliases.length,
        );

        aliases = aliases.concat(aliasesToHighlight);
      }

      return aliases;
    },
    highlightedAliasesInPlainText() {
      return this.highlightedAliases.map((aliasHtml) =>
        this.extractContent(aliasHtml),
      );
    },
    nonHighlightedAliases() {
      const aliasesNotHighlighted = this.team.aliases.filter(
        (alias) => !this.highlightedAliasesInPlainText.includes(alias),
      );

      return aliasesNotHighlighted.slice(
        0,
        ALIASES_LIMIT - this.highlightedAliases.length,
      );
    },
    notDisplayedAliasesCount() {
      return (
        this.team.aliases.length -
        this.highlightedAliases.length -
        this.nonHighlightedAliases.length
      );
    },
    notDisplayedCustomPropertiesCount() {
      return Math.max(
        0,
        (this.highlights["properties"]?.length ?? 0) -
          (this.highlightedCustomProperties.length ?? 0),
      );
    },
  },

  methods: {
    isHighlighted(property) {
      return this.highlightedProperties.includes(property);
    },
    extractContent(html) {
      return new DOMParser().parseFromString(html, "text/html").documentElement
        .textContent;
    },
    truncatedData(data, length = 200) {
      return data ? truncate(data, { length: length }) : "None";
    },
    isNestedValue(key) {
      return key.includes(".");
    },
    keyWithoutPrefix(key) {
      const tokens = key.split(".");

      return tokens.toSpliced(0, 1).join(".");
    },
    pluralize,
  },
};
</script>
