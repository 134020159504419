<template>
  <div>
    <ExpandButton
      :onClick="toggleTableExpanded"
      :expandedRows="areAllRowsExpanded"
    />
    <Table
      :rowKey="(row) => row.id"
      :columns="columns"
      :data="searchResults"
      :totalTableCount="filteredSearchCount"
      :paginationState="paginationState"
      :sortState="sortState"
      :loading="isFetchingSearchResults"
      :expandIconColumnIndex="-1"
      :expandIconAsCell="true"
      :expandedRowKeys="currentExpandedRows"
      @expandedRowsChange="handleExpandedRowsChange"
      @updateTableData="handleTableChange"
    >
      <template v-slot:name="team">
        <HighlightContentWithHref
          v-if="highlights(team, 'name')"
          :contentHtml="highlights(team, 'name')[0]"
          :href="team.href"
        />
        <a v-else :href="team.href">{{ team.name }}</a>
      </template>
      <template v-slot:services="team">
        <CollapsibleList
          v-if="team.services && team.services.length"
          :items="team.services"
          :numPeeking="1"
        >
          <template v-slot:item="{ slotProps }">
            <a :href="slotProps.item.href">
              {{ slotProps.item.name }}
            </a>
          </template>
        </CollapsibleList>
        <i v-else size="small"> No {{ $t("components") }} </i>
      </template>
      <template v-slot:expandedRowRender="team">
        <TeamSearchDetails :team="team" />
      </template>
    </Table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Table from "@/components/molecules/Table.vue";
import ExpandButton from "@/components/ExpandButton.vue";
import HighlightContentWithHref from "@/components/atoms/HighlightContentWithHref.vue";
import {
  saveTableStateToUrl,
  DEFAULT_SORTED_INFO,
} from "@/shared/table_helper.js";
import CollapsibleList from "@/components/CollapsibleList.vue";
import TeamSearchDetails from "@/search/tabs/TeamSearchDetails.vue";

export default {
  components: {
    CollapsibleList,
    Table,
    HighlightContentWithHref,
    ExpandButton,
    TeamSearchDetails,
  },

  props: {
    searchTerm: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentExpandedRows: [],
    };
  },

  computed: {
    ...mapState({
      searchResults: (state) => state.teamsSearch.searchResults,
      isFetchingSearchResults: (state) =>
        state.teamsSearch.isFetchingSearchResults,
      filteredSearchCount: (state) => state.teamsSearch.filteredCount,
      paginationState: (state) => state.teamsSearch.pagination,
      sortState: (state) => state.teamsSearch.sort,
    }),
    columns() {
      return [
        {
          title: "Name",
          key: "name",
          dataType: "String",
        },
        {
          title: this.$t("Components"),
          key: "services",
          dataType: "ServiceType",
          scopedSlots: { customRender: "services" },
        },
        {
          title: "Tags",
          key: "tags",
          dataIndex: "tags.nodes",
          dataType: "Types::TagConnectionType",
        },
      ];
    },
    areAllRowsExpanded() {
      return this.currentExpandedRows.length === this.searchResults.length;
    },
    recordsWithSummaryHighlights() {
      return this.searchResults.filter((record) => {
        if (!record.highlights) {
          return false;
        }

        const summaryProperties = [
          "aliases.value",
          "friendly_id_slugs.slug",
          "responsibilities",
          "properties.value",
        ];

        return Object.keys(record.highlights).some((highlight) =>
          summaryProperties.includes(highlight),
        );
      });
    },
  },

  watch: {
    searchResults() {
      this.expandRowsWithSummaryHighlights();
    },
  },

  created() {
    this.expandRowsWithSummaryHighlights();
  },

  methods: {
    ...mapActions({
      updateTeamData: "teamsSearch/updateTeamData",
    }),
    handleTableChange({ queryParams }) {
      let { sorter } = queryParams;

      if (!sorter?.order) {
        sorter = DEFAULT_SORTED_INFO;
      }

      saveTableStateToUrl(
        {
          pagination: queryParams.pagination,
          sorter,
          searchTerm: this.searchTerm,
        },
        { defaultSort: DEFAULT_SORTED_INFO },
      );
      this.updateTeamData({
        queryParams: { ...queryParams, sorter, searchTerm: this.searchTerm },
      });
    },
    highlights(object, property) {
      return object.highlights?.[property];
    },
    handleExpandedRowsChange(expandedRowKeys) {
      this.currentExpandedRows = expandedRowKeys;
    },
    toggleTableExpanded() {
      if (this.areAllRowsExpanded) {
        this.currentExpandedRows = [];
      } else {
        this.currentExpandedRows = this.searchResults.map((r) => r.id);
      }
    },
    expandRowsWithSummaryHighlights() {
      if (this.recordsWithSummaryHighlights.length) {
        this.currentExpandedRows = this.recordsWithSummaryHighlights.map(
          (r) => r.id,
        );
      }
    },
    sortOrder(key) {
      return this.sortState.key === key ? this.sortState.direction : undefined;
    },
  },
};
</script>
