<template>
  <img src="/OpsLevelLogoMark-Primary.svg" />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      required: false,
      default: "30px",
    },
  },
};
</script>

<style scoped>
@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  50% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
  }
  75% {
    transform: scale(1.3);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
img {
  animation-name: loading-animation;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  height: v-bind(size);
  width: v-bind(size);
  opacity: 0.75;
}
</style>
