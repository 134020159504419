<template>
  <div>
    <a-layout class="top-layout">
      <slot name="flash" />

      <div class="header-content">
        <img :src="logoPath" class="logo" alt="OpsLevel" />
        <a class="btn-skip-for-now" type="link" @click="handleSkipForNow"
          >Skip for now</a
        >
      </div>
      <a-layout-content class="global-content">
        <div class="content-wrapper width-60vw">
          <slot name="content">
            <div></div>
          </slot>
        </div>
      </a-layout-content>

      <div class="expando" />
      <CopyrightFooter />
    </a-layout>
  </div>
</template>

<script>
import CopyrightFooter from "./CopyrightFooter.vue";
import tracking from "@/modules/tracking/index.js";
import store from "@/store/index.js";
import { mapActions } from "vuex";

export default {
  components: { CopyrightFooter },
  inject: ["routes"],
  store,

  data() {
    return {};
  },

  computed: {
    logoPath() {
      return "/OpsLevelLogo-Primary.svg";
    },
  },

  created() {
    this.$store.registerModuleOnce("tracking", tracking);
  },

  methods: {
    ...mapActions("tracking", ["trackUserClick"]),
    async handleSkipForNow() {
      const path = this.routes.dashboard_path(); // save it before we await, it might get torn down

      await this.trackUserClick("freeTrialOnboardingSkipForNow");

      location.assign(path);
    },
  },
};
</script>

<style scoped lang="scss">
.global-content {
  display: flex;
  justify-content: center;
  margin: 24px 72px 72px 72px;
}

.logo {
  max-height: 34px;
  align-self: flex-start;
  margin: 28px;
}

.header-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.btn-skip-for-now {
  margin: 32px;
}

.width-60vw {
  width: 60vw;
}
</style>
