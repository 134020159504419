import Vue from "vue";
import Rails from "@rails/ujs";
import Antd, { Spin } from "ant-design-vue";
import i18next from "i18next";
import I18NextVue from "i18next-vue";
import "ant-design-vue/dist/antd.css";
import ApplicationLayout from "@/layouts/application.vue";
import ApplicationInnerOnlyLayout from "@/layouts/applicationInnerOnly.vue";
import SpinningLogo from "@/components/SpinningLogo.vue";
import "./shared/style.css";
import "./shared/transitions.css";
import SearchBar from "@/components/SearchBar.vue";
import linkify from "vue-linkify";
import * as Sentry from "@sentry/vue";
import VueHotkey from "v-hotkey";
import URLPersistence from "@/plugins/url-persistence/url-persistence.js";
import LoginLayout from "@/layouts/login.vue";
import OnboardingLayout from "@/layouts/Onboarding.vue";
import Flash from "@/components/Flash.vue";
import NotFoundError from "@/components/molecules/NotFoundError.vue";
import Sanitize from "@/plugins/sanitize.js";
import defaultLocale from "@/locales/default.json";

// https://gitlab.com/jklabsinc/OpsLevel/-/issues/9855
const ignoredErrors = [
  /apolloerror: error message not found/i,
  /Non-Error promise rejection captured with value: Object Not Found Matching Id/,
];

function getLayout(hideWrappers) {
  if (window.opslevel.isLoggedIn) {
    if (window.opslevel?.onboardingProps?.isFullPageOnboarding) {
      return OnboardingLayout;
    }

    if (hideWrappers) {
      return ApplicationInnerOnlyLayout;
    }

    return ApplicationLayout;
  }

  return LoginLayout;
}

export function createAppWrapper(contentComponent, hideWrappers = false) {
  if (window.sentrySettings?.dsn && window.sentrySettings.dsn !== "false") {
    Vue.config.devtools = false;
    Sentry.init({
      Vue: Vue,
      dsn: window.sentrySettings.dsn,
      logErrors: true,
      ignoreErrors: ignoredErrors,
    });
  }

  i18next.init({
    ns: ["default", "override"],
    defaultNS: "default",
    resources: {
      en: {
        default: defaultLocale,
      },
    },
    lng: "en",
  });

  Vue.use(Antd);
  Vue.use(I18NextVue, { i18next });
  Vue.config.productionTip = false;
  Vue.directive("linkified", linkify);
  Vue.use(URLPersistence);
  Vue.use(Sanitize);
  Vue.use(VueHotkey);
  Vue.config.ignoredElements = [/stripe-pricing-table/];
  // Set an animated OpsLevel logo as the app spinner
  Spin.setDefaultIndicator({
    indicator: (h) => {
      return h(SpinningLogo);
    },
  });

  // Rails.start() is run on import as part of the Rollup bundle, but not in ESM mode locally
  if (!window._rails_loaded) {
    Rails.start();
  }

  new Vue({
    el: "#app",

    components: {
      ApplicationLayout,
      Flash,
      SearchBar,
      LoginLayout,
      contentComponent,
    },

    render: (h) => {
      const scopedSlots = {
        content: () => {
          if (window.opslevel.isNotFound) {
            return h(NotFoundError);
          }

          if (!contentComponent) {
            return;
          }

          return h(contentComponent, {
            props: window.opslevel.contentProps,
          });
        },
        flash: () => h(Flash, { props: window.opslevel.flashProps }),
      };

      if (window.opslevel.searchBarProps) {
        scopedSlots["search-bar"] = () =>
          h(SearchBar, {
            props: window.opslevel.searchBarProps,
          });
      }

      return h(getLayout(hideWrappers), {
        props: window.opslevel.layoutProps,
        scopedSlots,
      });
    },
  });
}
