import { gql } from "@apollo/client";

export const SUGGESTION_FRAGMENT = gql`
  fragment SuggestionFragment on Suggestion {
    id
    name
    description
    suggestedOwner {
      id
      name
      href
    }
    owner {
      id
      name
      href
    }
    suggestedOwners(first: 3) {
      nodes {
        team {
          id
          name
          href
        }
        contributionsCount
        contributors {
          user {
            id
            name
            htmlUrl
          }
          contributionCount
        }
      }
    }
    suggestedService {
      permissions {
        canUpdate
      }
    }
    action {
      serviceId
      serviceName
      serviceHref
      serviceDescription
      owner {
        id
        name
        href
      }
      type
    }
    aliases
  }
`;

export const SUGGESTION_GROUP_FRAGMENT = gql`
  fragment SuggestionGroupFragment on SuggestionGroup {
    id
    name
    description
    aliases
    timestamps {
      createdAt
      updatedAt
    }
    suggestedService {
      permissions {
        canUpdate
      }
    }
    action {
      serviceId
      serviceName
      serviceHref
      serviceDescription
      owner {
        id
        name
        href
      }
      type
    }
    suggestedOwner {
      id
      name
      href
    }
    owner {
      id
      name
      href
    }
    suggestedOwners(first: 3) {
      nodes {
        team {
          id
          name
          href
        }
        contributionsCount
        contributors {
          user {
            id
            name
            htmlUrl
          }
          contributionCount
        }
      }
    }
  }
`;

export const REPOSITORY_FRAGMENT = gql`
  fragment RepositoryFragment on Repository {
    id
    name
    organization
    displayName
    type
    url
    htmlUrl
    href
  }
`;

export const ALERT_SOURCE_FRAGMENT = gql`
  fragment AlertSourceFragment on AlertSource {
    id
    name
    type
    url
  }
`;

export const CODE_ISSUE_PROJECT_FRAGMENT = gql`
  fragment CodeIssueProjectFragment on CodeIssueProject {
    id
    name
    externalUrl
    integration {
      id
      name
      displayName
      href
      type
      __typename
    }
    __typename
  }
`;

export const DEPLOY_FRAGMENT = gql`
  fragment DeployFragment on Deploy {
    id
    author
    commitSha
    deployedAt
    deployerName
    deployNumber
    providerName
    providerType
    serviceAlias
  }
`;

export const CHECK_RESULT_FRAGMENT = gql`
  fragment CheckResultFragment on CheckResult {
    createdAt
    serviceAlias
    check {
      name
      type
      integration {
        name
        type
      }
    }
  }
`;

export const KUBERNETES_FRAGMENT = gql`
  fragment KubernetesFragment on KubernetesIntegration {
    id
    name
    displayName
    htmlUrl
    lastEventReceived
  }
`;

export const INTEGRATION_FRAGMENT = gql`
  fragment IntegrationFragment on Integration {
    type
  }
`;

// Renamed unignoredCount to totalCount because unignored count is the total number of suggestions that are not ignored
// and that is the number we display in the tab and in the header above the table.
// Renamed totalCount to filteredCount because totalCount is the number of suggestions returned after filtering
export const SUGGESTIONS_INDEX_QUERY = gql`
  query Suggestions(
    $after: String
    $first: Int
    $sortBy: SuggestionSortEnum
    $filter: [SuggestionFilterInput!]
    $search: String
    $sourceIntegrationTypes: [ServiceDetectionIntegrationEnum!]
    $sourceIntegrationIds: [ID!]
    $teamIds: [SuggestionTeamIdFilterInput]
  ) {
    account {
      hasDeployIntegrations
      hasDeploys
      suggestions(
        after: $after
        first: $first
        sortBy: $sortBy
        filter: $filter
        search: $search
        sourceIntegrations: $sourceIntegrationTypes
        integrationIds: $sourceIntegrationIds
        teamIds: $teamIds
      ) {
        totalCount: unignoredCount
        filteredCount: totalCount
        nodes {
          timestamps {
            createdAt
            updatedAt
          }
          ...SuggestionFragment
          sources {
            edges {
              createdAt
              metadata
              node {
                __typename
                ...RepositoryFragment
                ...AlertSourceFragment
                ...DeployFragment
                ...CheckResultFragment
                ...CodeIssueProjectFragment
                ...IntegrationFragment
                ...KubernetesFragment
              }
            }
          }
        }
      }
    }
  }
  ${SUGGESTION_FRAGMENT}
  ${REPOSITORY_FRAGMENT}
  ${ALERT_SOURCE_FRAGMENT}
  ${DEPLOY_FRAGMENT}
  ${CHECK_RESULT_FRAGMENT}
  ${CODE_ISSUE_PROJECT_FRAGMENT}
  ${KUBERNETES_FRAGMENT}
  ${INTEGRATION_FRAGMENT}
`;

export const SUGGESTION_GROUPS_INDEX_QUERY = gql`
  query SuggestionGroups(
    $after: String
    $first: Int
    $sortBy: SuggestionGroupSortEnum
    $filter: [SuggestionGroupFilterInput!]
    $search: String
    $sourceIntegrationTypes: [ServiceDetectionIntegrationEnum!]
    $sourceIntegrationIds: [ID!]
    $teamIds: [SuggestionTeamIdFilterInput]
  ) {
    account {
      hasDeployIntegrations
      hasDeploys
      suggestionGroups(
        after: $after
        first: $first
        sortBy: $sortBy
        filter: $filter
        search: $search
        sourceIntegrations: $sourceIntegrationTypes
        integrationIds: $sourceIntegrationIds
        teamIds: $teamIds
      ) {
        totalCount: unignoredCount
        filteredCount: totalCount
        nodes {
          ...SuggestionGroupFragment
          sources {
            edges {
              createdAt
              metadata
              node {
                __typename
                ...RepositoryFragment
                ...AlertSourceFragment
                ...DeployFragment
                ...CheckResultFragment
                ...CodeIssueProjectFragment
                ...IntegrationFragment
                ...KubernetesFragment
              }
            }
          }
        }
      }
    }
  }
  ${REPOSITORY_FRAGMENT}
  ${ALERT_SOURCE_FRAGMENT}
  ${DEPLOY_FRAGMENT}
  ${CHECK_RESULT_FRAGMENT}
  ${SUGGESTION_GROUP_FRAGMENT}
  ${CODE_ISSUE_PROJECT_FRAGMENT}
  ${KUBERNETES_FRAGMENT}
  ${INTEGRATION_FRAGMENT}
`;

export const SUGGESTION_SET_IGNORED_VALUE_MUTATION = gql`
  mutation suggestionsUpdateIgnoredValue($input: SuggestionsUpdateInput!) {
    suggestionsUpdate(input: $input) {
      results {
        id
        name
      }
      notActioned
      errors {
        message
        path
      }
    }
  }
`;

export const SUGGESTION_UPDATE_MUTATION = gql`
  mutation suggestionUpdate($input: SuggestionUpdateInput!) {
    suggestionUpdate(input: $input) {
      suggestion {
        ...SuggestionFragment
        ...SuggestionGroupFragment
      }
      errors {
        message
        path
      }
    }
  }
  ${SUGGESTION_FRAGMENT}
  ${SUGGESTION_GROUP_FRAGMENT}
`;

export const ACTION_SUGGESTION_MUTATION = gql`
  mutation actionSuggestions($input: SuggestionActionInput!) {
    suggestionAction(input: $input) {
      results {
        serviceId
        suggestionId
        name
        alias
        type
        url
      }
      notActioned
      errors {
        message
        path
      }
    }
  }
`;

export const SUGGESTIONS_INTEGRATIONS_QUERY = gql`
  query fetchSuggestionSourceIntegrations {
    account {
      suggestionSourceIntegrations {
        nodes {
          id
          name
          type
        }
      }
    }
  }
`;

export const SERVICE_DETECTION_INTEGRATION_TYPES = gql`
  query serviceDetectionIntegrationTypes {
    account {
      serviceDetectionIntegrationTypes
    }
  }
`;

export const DETACH_SUGGESTION_ALIAS_MUTATION = gql`
  mutation detachSuggestionAlias($input: DetachSuggestionAliasInput!) {
    detachSuggestionAlias(input: $input) {
      suggestionAlias
      errors {
        message
        title
        path
      }
    }
  }
`;
