<template>
  <a-card>
    <div v-if="isFetchingSearchResults" class="loading">
      <SpinningLogo />
    </div>
    <h3 v-if="isEmptySystemSearchResult" class="hint">
      <p>
        We couldn't find any results matching "<b>{{ searchTerm }}</b
        >".
        <br />
        Please try using a different search term.
      </p>
    </h3>
    <template v-else>
      <h3>
        {{ headerText }}
      </h3>
      <TeamsSearchTable :searchTerm="searchTerm" />
    </template>
  </a-card>
</template>

<script>
import { mapState } from "vuex";
import pluralize from "pluralize";
import SpinningLogo from "@/components/SpinningLogo.vue";
import TeamsSearchTable from "@/search/tabs/TeamsSearchTable.vue";

export default {
  components: {
    SpinningLogo,
    TeamsSearchTable,
  },

  props: {
    searchTerm: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      isFetchingSearchResults: (state) =>
        state.teamsSearch.isFetchingSearchResults,
      filteredSearchCount: (state) => state.teamsSearch.filteredCount,
    }),
    isEmptySystemSearchResult() {
      return this.filteredSearchCount === 0 && !this.isFetchingSearchResults;
    },
    headerText() {
      const count = this.filteredSearchCount;

      return `${count} ${pluralize("Team", count)}`;
    },
  },
};
</script>

<style scoped>
.loading {
  display: flex;
  justify-content: center;
}
</style>
