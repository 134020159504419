<template>
  <span v-hotkey="keymap">
    <a-auto-complete
      ref="searchBar"
      :showSearch="true"
      class="search-bar"
      :defaultActiveFirstOption="false"
      :placeholder="placeholder"
      :defaultValue="value"
      :notFoundContent="notFoundContent"
      :dropdownStyle="dropdownStyle"
      optionLabelProp="title"
      @search="handleSearchChange"
      @select="handleSelect"
    >
      <a-input @pressEnter="handleImmediateSearch">
        <a-icon slot="prefix" type="search" />
        <code
          slot="suffix"
          style="line-height: 1"
          @click="handleImmediateSearch"
        >
          /
        </code>
      </a-input>
      <template v-slot:dataSource>
        <template v-if="shouldShowSuggestions">
          <a-select-option v-for="option in searchOptions" :key="option.tab">
            <a-icon type="search" class="search-option-icon" />{{
              trimmedSearchValue
            }}
            <a-tag style="float: right">{{ option.label }}</a-tag>
          </a-select-option>
        </template>
        <a-select-option v-for="service in dataSourceItems" :key="service.href">
          <div class="search-option">
            <ComponentTypeBadge
              showTooltip
              :name="serviceType(service)"
              :icon="iconName(service)"
              :color="iconColor(service)"
              :small="true"
            />
            {{ service.name }}
          </div>
        </a-select-option>
        <a-select-option
          v-if="isFetchingSearchResults"
          key="isFetchingSearchResults"
          :disabled="true"
        >
          <div class="loading-spinner">
            <SpinningLogo />
          </div>
        </a-select-option>
      </template>
      <a-icon slot="suffix" type="" class="certain-category-icon" />
    </a-auto-complete>
  </span>
</template>

<script>
import search from "@/modules/search/index.js";
import { mapActions, mapState } from "vuex";
import store from "@/store/index.js";
import { debouncedSearch } from "@/shared/search_helper.js";
import { featureFlags } from "@/mixins/featureFlags.js";
import { Tabs } from "@/search/Index.vue";
import SpinningLogo from "./SpinningLogo.vue";
import ComponentTypeBadge from "@/component_types/ComponentTypeBadge.vue";

const MIN_NUM_CHARS_TO_AUTO_COMPLETE = 2;
const MIN_NUM_CHARS_TO_SEARCH = 2;
const MAX_NUM_SEARCH_RESULTS = 5;
const MAX_NUM_CHARS_IN_SEARCH_TERM = 500;

export default {
  name: "SearchBar",

  components: {
    SpinningLogo,
    ComponentTypeBadge,
  },

  mixins: [featureFlags],

  props: {
    initValue: {
      type: String,
      required: true,
    },
    urls: {
      type: Object,
      required: true,
    },
    elasticsearchEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  store,

  data() {
    return {
      value: this.initValue,
      Tabs,
    };
  },

  computed: {
    ...mapState({
      searchResults: (state) => state.search.searchResults,
      currentSearchTab: (state) => state.search.currentSearchTab,
      isFetchingSearchResults: (state) => state.search.isFetchingSearchResults,
    }),
    placeholder() {
      return "Search or jump to...";
    },
    dataSource() {
      // this.searchResults can contain a hash with an error - we will handle that case explicitly here.
      const results = Array.isArray(this.searchResults)
        ? this.searchResults
        : [];

      return results;
    },
    dataSourceItems() {
      return this.shouldShowSuggestions ? this.dataSource : [];
    },
    shouldShowSuggestions() {
      return (
        this.validSearchQuery &&
        this.trimmedSearchValue.length >= MIN_NUM_CHARS_TO_AUTO_COMPLETE
      );
    },
    searchOptions() {
      return [{ tab: this.currentSearchTab, label: "all of OpsLevel" }];
    },
    validSearchQuery() {
      if (
        this.trimmedSearchValue &&
        this.trimmedSearchValue.length < MIN_NUM_CHARS_TO_SEARCH
      ) {
        return false;
      }

      // the regex /\S/ checks `this.value` for a single non-whitespace char
      return this.trimmedSearchValue && /\S/.test(this.trimmedSearchValue);
    },
    notFoundContent() {
      if (this.shouldShowSuggestions) {
        return this.techDocSearchEnabled
          ? `No ${this.$t("component")} results found`
          : "No results found";
      }

      return " "; // string with a single space is necessary here to force a render
    },
    dropdownStyle() {
      if (this.shouldShowSuggestions) {
        return {};
      }

      return { visibility: "hidden" };
    },
    keymap() {
      return {
        "/": this.focusSearch,
      };
    },
    trimmedSearchValue() {
      return this.value.trim();
    },
    techDocSearchEnabled() {
      return (
        this.hasFeatureFlag("tech_docs_search_ui") &&
        !this.hasFeatureFlag("disable_repositories")
      );
    },
  },

  created() {
    this.$store.registerModuleOnce("search", search({ itemsPerPage: 5 }));
  },

  methods: {
    ...mapActions({
      fetchServiceSearchResults: "search/fetchServiceSearchResults",
      resetSearchResults: "search/resetSearchResults",
    }),
    focusSearch() {
      this.$refs.searchBar.focus();
    },
    handleSearchChange(value) {
      value = value.substring(0, MAX_NUM_CHARS_IN_SEARCH_TERM);
      this.value = value;

      if (this.shouldShowSuggestions) {
        debouncedSearch.cancel();
        this.handleDebounceSearch();
      } else {
        this.resetSearchResults();
      }
    },
    handleSelect(value) {
      if (value.startsWith("/")) {
        window.location.href = `${window.location.origin}${value}`;
      } else {
        window.location.href = this.redirectToSearchIndexWithFindTerm(value);
      }
    },
    handleImmediateSearch() {
      if (this.validSearchQuery) {
        window.location.href = this.redirectToSearchIndexWithFindTerm(
          this.currentSearchTab,
        );
      }
    },
    redirectToSearchIndexWithFindTerm(tab) {
      return `${this.urls.search.index}?findTerm=${encodeURIComponent(
        this.trimmedSearchValue,
      )}#${tab}`;
    },
    handleDebounceSearch: debouncedSearch,
    // Search helper debounces the executeSearch by default. So we redirect to the fetcher from here.
    executeSearch() {
      this.fetchServiceSearchResults({
        search: this.trimmedSearchValue,
        first: MAX_NUM_SEARCH_RESULTS,
      });
    },
    serviceType(service) {
      return service.type.name;
    },
    iconName(service) {
      return service.type.icon.name;
    },
    iconColor(service) {
      return service.type.icon.color;
    },
  },
};
</script>

<style scoped>
.search-bar {
  width: 300px;
  vertical-align: middle;
}
.search-option-icon {
  padding-right: 8px;
}
.search-option {
  display: flex;
  align-items: center;
}
.loading-spinner {
  display: flex;
  justify-content: center;
}
code {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background: #f9f5f1;
  padding: 2px;
}
</style>
