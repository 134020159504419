<template>
  <div class="user-with-avatar">
    <Avatar
      :userName="userName"
      :gravatarHref="gravatarHref"
      :size="gravatarSize"
      styleProps="vertical-align: middle; float: left"
      shape="circle"
    />
    <span class="user-container">
      <span v-if="title">
        {{ displayTitle }}
      </span>
      <span>
        <a v-if="userHref" :href="userHref">
          <span slot="userName">
            <slot name="userName">
              {{ displayName }}
            </slot>
          </span>
        </a>
        <span v-else slot="userName">
          <slot name="userName">
            {{ displayName }}
          </slot>
        </span>
        <span v-if="isDeactivated()" style="font-style: italic">
          (Deactivated)
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import { truncate } from "lodash";
import Avatar from "./Avatar.vue";

export default {
  name: "UserWithAvatar",
  components: { Avatar },

  props: {
    userName: {
      type: String,
      required: false,
      default: null,
    },
    userHref: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    gravatarHref: {
      type: String,
      required: false,
      default: null,
    },
    gravatarSize: {
      type: String,
      required: false,
      default: "small",
    },
    truncateLength: {
      type: Number,
      required: false,
      default: 25,
    },
    deactivatedAt: {
      type: Date,
      required: false,
      default: null,
    },
  },

  computed: {
    displayName() {
      return truncate(this.userName, this.truncateLength);
    },
    displayTitle() {
      return truncate(this.title, this.truncateLength);
    },
  },

  methods: {
    isDeactivated() {
      return !!this.deactivatedAt;
    },
  },
};
</script>

<style scoped>
.user-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 8px;
  white-space: nowrap;
  font-size: 14px;
  color: #8c8c8c;
}
.user-with-avatar {
  display: flex;
  align-items: center;
}
</style>
